<template>
    <div>
        <router-view />
    </div>
</template>

<script>
export default {
    name: 'App',
};
</script>

<style>
/* Global styles can be added here */
body {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #f5f5f5;
}
</style>