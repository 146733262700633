<!-- frontend/src/views/Home.vue -->

<template>
  <div>
    <div class="container">
      <h1>Home</h1>
      <p>Welcome to the Home page!</p>
      <p>We are currently under construction. Be back soon!</p>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'HomeApp',
};
</script>

<style lang="scss" >
@use "../styles/main.scss";

</style>