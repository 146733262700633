import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import rippleEffect from '@vendor/ripple-effect';

createApp(App)
  .use(router)
  .use(rippleEffect, {
    color: 'rgba(255, 255, 255, 0.3)',
    eventListener: 'click',
  })
  .mount('#app');