// rippleEffect.js
export const rippleEffect = {
    install(app, options = {}) {
      const defaultSettings = {
        color: "rgba(255, 255, 255, 0.4)",
        eventListener: "click",
      };
  
      const settings = { ...defaultSettings, ...options };
  
      // Core ripple animation function
      const createRipple = (e, el, rippleColor) => {
        const { clientX, clientY } = e.touches?.[0] ?? e;
        const rect = el.getBoundingClientRect();
        const btnWidth = el.clientWidth;
        
        // Calculate ripple position
        const rippleX = clientX - rect.left - btnWidth;
        const rippleY = clientY - rect.top - btnWidth;
  
        // Create ripple element
        const ripple = document.createElement("span");
        ripple.style.cssText = `
          position: absolute;
          width: ${btnWidth * 2}px;
          height: ${btnWidth * 2}px;
          border-radius: 50%;
          transition: transform 700ms, opacity 700ms;
          transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
          background: ${rippleColor};
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100%;
          left: ${rippleX}px;
          top: ${rippleY}px;
          transform: scale(0);
          pointer-events: none;
        `;
  
        // Setup container element
        el.style.overflow = "hidden";
        if (getComputedStyle(el).position === "static") {
          el.style.position = "relative";
        }
  
        el.appendChild(ripple);
  
        // Trigger animation
        requestAnimationFrame(() => {
          ripple.style.transform = "scale(1)";
          ripple.style.opacity = "0";
        });
  
        // Cleanup
        setTimeout(() => ripple.remove(), 700);
      };
  
      // Vue directive
      app.directive('ripple', {
        mounted(el, binding) {
          const rippleColor = binding.value?.color || settings.color;
          const eventType = binding.value?.event || settings.eventListener;
  
          el.addEventListener(eventType, (e) => createRipple(e, el, rippleColor));
        },
        unmounted(el, binding) {
          const eventType = binding.value?.event || settings.eventListener;
          el.removeEventListener(eventType, createRipple);
        }
      });
    }
  };
  
  export default rippleEffect;